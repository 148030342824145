  <template>
    <b-form
      class="mr-2"
      @submit.prevent
    >
      <b-row>
        <!-- tanggal masuk -->
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-group
            label="Tanggal Stok Opname"
            label-for="v-date-in"
          >
            <b-form-input
              id="v-date-in"
              v-model="formPayload.date"
              type="date"
              class="custom__input pt-1"
              placeholder="First Name"
              :disabled="isDetail"
            />
            <small
              v-if="message.date && Array.isArray(message.date) && message.date.length > 0"
              class="text-error fw-bold-500 size12"
            >{{ message.date.join(', ') }}</small>
          </b-form-group>
        </b-col>
        
        <!-- pilih gudang -->
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-group
            label="Pilih Lokasi SKU"
            label-for="v-gudang"
          >
            <v-select
              v-model="selectedWarehouseName"
              style="background: #EFF1F5;border: 1px solid #E4E5EC;border-radius: 8px;"
              label="name"
              placeholder="Pilih Lokasi SKU"
              :options="optionsWarehouse"
              :disabled="isDetail"
              @input="setWarehouse"
            />
            <small
              v-if="message.warehouse_uuid && Array.isArray(message.warehouse_uuid) && message.warehouse_uuid.length > 0"
              class="text-error fw-bold-500 size12"
            >{{ message.warehouse_uuid.join(', ') }}</small>
          </b-form-group>
        </b-col>

        <!-- Catatan -->
        <b-col
          cols="12"
          class="mb-2"
        >
          <b-form-group
            label="Catatan"
            label-for="catatan"
          >
            <b-form-textarea
              id="textarea-default"
              v-model="formPayload.note"
              class="border-8"
              placeholder="Catatan"
              rows="3"
              style="background-color: #EFF1F5;"
              :disabled="isDetail"
            />
            <small
              v-if="message.note && Array.isArray(message.note) && message.note.length > 0"
              class="text-error fw-bold-500 size12"
            >{{ message.note.join(', ') }}</small>
          </b-form-group>
        </b-col>

      <!-- pilih produk -->
      <b-row v-for="(item, index) in formPayload.items" :id="index" :key="index" ref="row" class="m-0" style="width: 738.6px;">
        <b-col
          cols="6"
        >
          <b-form-group
            label="Pilih Produk"
            label-for="v-produk"
          >
            <!-- <v-select
              v-model="formPayload.product_uuid"
              label="name"
              placeholder="Pilih Produk"
              :options="options"
              @search="onSearch"
            /> -->
            <span
              class="d-flex align-items-center text-dark fw-bold-600 pl-1"
              style="background: #EFF1F5;border: 1px solid #E4E5EC;border-radius: 8px;height: 52px; font-size: 14px;"
              role="button"
              @click="openProductSelectionModal(index)"
            >
              {{ formPayload.items[index].product_uuid.title || 'Pilih Produk' }}
            </span>
            <small
              v-if="message.product_uuid && Array.isArray(message.product_uuid) && message.product_uuid.length > 0"
              class="text-error fw-bold-500 size12"
            >{{ message.product_uuid.join(', ') }}</small>
          </b-form-group>
        </b-col>

        <!-- jumlah -->
        <b-col
          cols="3"
        >
          <b-form-group
            label="Jumlah"
            label-for="v-qty"
          >
            <b-form-input
              id="v-qty"
              v-model="formPayload.items[index].qty"
              class="custom__input"
              type="number"
              placeholder="100"
              :disabled="isDetail"
            />
            <small
              v-if="message.qty && Array.isArray(message.qty) && message.qty.length > 0"
              class="text-error fw-bold-500 size12"
            >{{ message.qty.join(', ') }}</small>
          </b-form-group>
        </b-col>

        <!-- Remove Item -->
        <b-col
          cols="3"
          class="text-right align-self-center"
        >
            <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" class="" @click="removeItem(index)">
              <feather-icon icon="XIcon" />
              <span>Hapus</span>
            </b-button>
        </b-col>
      </b-row>

        <b-col cols="12">
          <hr>
        </b-col>

        <b-col cols="12">
          <b-button class="btn-block mb-4" variant="outline-dark border-white btn__add--product" @click="repeatAgain">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Tambah Produk</span>
          </b-button>
        </b-col>

      </b-row>
      <AddProductStokOpname
        :products="products" 
        :get-product="getProduct" 
        @selectedProduct="getSelectedProduct" 
        :filter="filter" 
        :selectedWarehouseName="selectedWarehouseName"
      />
    </b-form>
  </template>

  <script>
  // eslint-disable-next-line import/no-extraneous-dependencies
  import _ from 'lodash'
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BButton
  } from 'bootstrap-vue'
  import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import AddProductStokOpname from '@/components/Stock/Modal/AddProductStokOpname.vue'

  export default {
    components: {
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
      vSelect,
      BFormTextarea,
      AddProductStokOpname
    },
    directives: {
      Ripple,
    },
    setup() {
      return {
        checkPermission, successNotification, errorNotification,
      }
    },
    props: {
      messages: {
        type: '',
      },
    },
    data() {
      return {
        formPayload: {
          warehouse_uuid: '',
          date: '',
          note: '',
          items: [
            {
              product_uuid: '',
              qty: 0,
            },
          ],
        },
        options: [],
        optionsWarehouse: [],
        optionsOrigin: [],
        optionsDestination: [],
        resultProduct: {},
        products: [],
        message: '',
        isDetail: this.$store.state.stockOpname.isDetail,
        filter: {
          search: '',
          per_page: 10,
        },
        selectedWarehouseName: ''
      }
    },
    watch: {
      'formPayload.product_uuid': function (value) {
        this.getProductDetail(value.uuid)
      },
      'formPayload.warehouse_uuid': function (value) {
        this.$store.commit('stockOpname/setSelectedWarehouse', value)
      },
      '$store.state.stockOpname.detailItem': {
        handler(value) {
          if (value) {
            this.formPayload = {
              uuid: value.uuid,
              product_uuid: value.product,
              warehouse_uuid: value.warehouse,
              qty: value.qty,
              date: value.qty,
              note: '',
            }
          }
        },
        deep: true,
      },
      formPayload: {
        handler(value) {
          const data = {
            ...this.formPayload,
            warehouse_uuid: value.warehouse_uuid,
          }
          this.$emit('payload', data || {})
        },
        deep: true,
      },
      messages(value) {
        this.message = value
      },
      selectedWarehouseName: function (newWarehouse, oldWarehouse) {
        if (newWarehouse !== oldWarehouse) {
          this.formPayload.items = [{
            product_uuid: '',
            qty: 0,
          }];
        }
      },
    },
    mounted() {
      // this.getData()
      if (this.isDetail === true) {
        this.getProductDetail(this.$store.state.stockOpname.detailItem?.product?.uuid)
        this.formPayload = {
          uuid: this.$store.state.stockOpname.detailItem?.uuid,
          product_uuid: this.$store.state.stockOpname.detailItem?.product,
          warehouse_uuid: this.$store.state.stockOpname.detailItem?.warehouse,
          qty: this.$store.state.stockOpname.detailItem?.qty,
          date: this.$store.state.stockOpname.detailItem?.date,
          note: this.$store.state.stockOpname.detailItem?.note,
        }
      }
      if (this.$route.query.ctaId) {
        this.getProductDetail(this.$route.query.ctaId)
        this.formPayload.product_uuid = {
          uuid: this.$route.query.ctaId,
          name: this.$route.query.ctaName,
        }
      }
      // this.getWarehouse()
      this.getWarehouses(0);
      this.getWarehouses(1);
    },
    methods: {
      openProductSelectionModal(index) {
        this.indexProduct = index
        if (!this.isDetail) {
          this.$bvModal.show('modal-add-product')    
        }
      },
      getSelectedProduct(product) {
        this.formPayload.items[this.indexProduct].product_uuid = {
          label: product.uuid,
          title: product.name,
        }
      },
      onSearch(search, loading) {
        if (search.length) {
          loading(true)
          this.searchProduct(loading, search, this)
        } else {
          loading(true)
          setTimeout(() => {
            this.options = []
            this.getData()
            loading(false)
          }, 500)
        }
      },
      searchProduct: _.debounce((loading, search, vm) => {
        vm.$store.dispatch('parentProduct/getData', {
          uuid: '',
          params: {
            search: search,
          },
        })
          .then(response => {
            // eslint-disable-next-line no-param-reassign
            vm.options = []
            response.data.data.data.forEach(item => {
              if (!item.is_default) {
                vm.options.push({
                  uuid: item.uuid,
                  name: item.name,
                })
              }
            })
            loading(false)
          })
      }, 300),
      getData(id) {
        this.$store.dispatch('parentProduct/getData', {
          uuid: `/${id || ''}`,
          params: '',
        }).then(result => {
          if (!id) {
            result.data.data.data.forEach(element => {
              if (!element.is_default) {
                this.options.push({
                  uuid: element.uuid,
                  name: element.name,
                })
              }
            })
          } else {
            this.resultProduct = result.data.data
            this.$store.commit('stockOpname/setResultProduct', result.data.data)
          }
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
      },
      setWarehouse(item) {
        this.formPayload.warehouse_uuid = item?.uuid || '';
        this.selectedWarehouseName = item?.name || '';
        this.page = 1;

        if (item) {
          this.getProduct();
        } else {
          this.next_page_url = '';
          this.products = [];
        }
      },
      getProduct() {
        const queryParams = this.filter
        queryParams.warehouse_uuid = this.formPayload.warehouse_uuid
        queryParams.is_show_stock = true

        this.$store.dispatch('parentProduct/getData', {
          uuid: '',
          params: queryParams,
        }).then(result => {
          this.next_page_url = result.data.data.next_page_url
          this.products = result.data.data.data
        }).catch(err => {
          console.log(err)
        })
      },
      getProductDetail(id) {
        this.optionsWarehouse = []
        this.$store.dispatch('parentProduct/getData', {
          uuid: `${id || ''}`,
          params: '',
        }).then(result => {
          // this.optionsWarehouse = []
          this.resultProduct = result.data.data
          // this.formPayload.product_uuid = {
          //   name: result.data.data.name,
          //   uuid: result.data.data.uuid,
          // }
          // if (this.options.some(e => e.uuid !== result.data.data.uuid)) {
          //   console.log(this.options.some(e => e.uuid !== result.data.data.uuid));
          //   // this.options.unshift(this.formPayload.product_uuid)
          // }

          // this.$store.commit('stockOpname/setResultProduct', result.data.data)
          // this.optionsWarehouse = result.data.data.stock_in_warehouse.filter(item => item.type !== 'supplier')
          // .map(filteredItem => ({
          //   uuid: filteredItem.uuid,
          //   name: filteredItem.name,
          // }));
          // console.log(this.optionsWarehouse,'ini')
          // console.log(this.resultProduct,'ini product')
          // setTimeout(() => {
          //   this.optionsWarehouse
          // }, 500)
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
      },
      getWarehouse() {
        this.$store.dispatch('warehouse/getData', {
          params: {
            per_page: 50,
          },
        }).then(result => {
          result.data.data.data.forEach(element => {
            this.optionsWarehouse.push({
              label: element.uuid,
              title: element.name,
            })
          })
          // this.optionsWarehouse.unshift({ label: '', title: 'Pilih Gudang' })
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
      },
      // Multi Product
      getWarehouses(hasStock) {
        this.$store.dispatch('warehouse/getData', {
          params: {
            show_all: hasStock == 0 ? 1 : 0,
            // has_stock: hasStock,
          },
        }).then(result => {
          if (hasStock == 0) {
            this.optionsDestination = [...result.data.data]
          }
          if (hasStock == 1) {
            this.optionsOrigin = [...result.data.data]
            this.optionsWarehouse.push(...result.data.data)
          }
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
      },
      removeItem(index) {
        this.formPayload.items.splice(index, 1)
      },
      repeatAgain() {
        this.formPayload.items.push({
          product_uuid: '',
          qty: 0,
        })
      },
    },
  }
  </script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .btn__add--product {
    background-color: #FFFFFF !important;
    border: 1px solid #E4E5EC !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04) !important;
    border-radius: 8px;
    font-weight: 800;
  }
  </style>
